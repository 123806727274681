import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Link } from 'gatsby';
import React from 'react';

interface BackButtonProps {
  href: string;
  sx: SxProps;
}

export const BackButton = (props: BackButtonProps) => {
  const { href, sx } = props;
  return (
    <Typography color="primary.main" sx={sx}>
      <Link
        to={href || '#'}
        style={{
          color: 'inherit',
          fontFamily: 'Rubik',
          fontSize: '16px',
          fontWeight: 500,
          textDecoration: 'none',
          textTransform: 'none'
        }}
      >
        <span
          style={{
            fontFamily: 'Inter',
            marginRight: '8px'
          }}
        >
          ←
        </span>
        Back
      </Link>
    </Typography>
  );
};
