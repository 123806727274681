import { Button, CardMedia, Theme, Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { CraftGql_Body_NewsLetter_BlockType, NewsArticleQueryQuery } from '../../../graphql-types';
import { IconWave } from '../../assets/icons/how-it-works/wave';
import { IconFacebook } from '../../assets/icons/social/facebook';
import { IconLinkedIn } from '../../assets/icons/social/linkedin';
import { IconTwitter } from '../../assets/icons/social/twitter';
import '../../assets/styles/style.css';
import { CAPTIONS_TEXT_COLOR, LL_BACKGROUND_COLOR } from '../../colors';
import { BackButton } from '../../components/back-button';
import { ExternalLink } from '../../components/external-link';
import Layout from '../../components/layout';
import { NavbarBreadcrumbs } from '../../components/navbar-breadcrumbs';
import { NewsCard } from '../../components/news-card';
import { Absolute } from '../../components/primitives/absolute';
import { FlexBox } from '../../components/primitives/flex';
import { Width80Box } from '../../components/primitives/Width80Box';
import { ReusableComponentsType } from '../../componentsEnum';
import { linkStyle } from '../../constants';
import { LinkItem } from '../../interfaces/link';
import { NewsLetterSection } from '../../sections/news-letter-section';
import { formatDateTimeWithMonthName } from '../../utils/formatters';

interface NewArticleProps {
  location: any;
  data: NewsArticleQueryQuery;
}
const homeBreadcrumb = { label: 'Home', value: 'home', href: '/' };

const ARTICLE_ANCHOR_COLOR = '#3397ff';

const newsArticle = ({ data, location }: NewArticleProps) => {
  const theme: Theme = useTheme();
  const minBreadcrumbValue = 2;
  const [breadcrumbs, setBreadcrumbs] = React.useState<LinkItem[]>([]);

  /********************* STYLE *********************/

  const sxProps: { [className: string]: SxProps } = {
    articleWrapper: {
      paddingX: '20px',
      paddingY: '100px',
      marginX: 'auto',
      maxWidth: '960px',
      [theme.breakpoints.down('md')]: {
        paddingY: '60px',
        width: '100%'
      }
    },
    articleTags: {
      mt: '20px',
      mx: 'auto',
      overflowX: 'auto',
      justifyContent: 'center',
      pb: '8px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start'
      }
    },
    articleTitle: {
      color: 'primary.main',
      fontFamily: 'Tropiline',
      marginTop: '28px',
      paddingX: '40px',
      textAlign: 'center'
    },
    articlePostDate: {
      color: CAPTIONS_TEXT_COLOR,
      marginY: '32px',
      textAlign: 'center'
    },
    articleImg: {
      borderRadius: '24px 24px 24px 0',
      maxHeight: '528px',
      maxWidth: '952px',
      mx: 'auto',
      width: '100%'
    },
    articleSubTitle: {
      color: 'primary.main',
      marginTop: { xs: '25px', md: '76px' },
      marginBottom: { xs: '25px', md: '40px' }
    },
    articleShareContainer: {
      alignItems: 'center',
      display: 'flex',
      mt: '60px',
      mx: 'auto',
      width: 'fit-content'
    },
    articleButton: {
      alignItems: 'baseline',
      display: 'flex',
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontWeight: 500,
      marginTop: '52px',
      marginX: 'auto',
      textTransform: 'none'
    },
    tagStyle: {
      display: 'block',
      border: '2px solid' + theme.palette.primary.main,
      borderRadius: '100px',
      color: 'secondary.main',
      fontWeight: 500,
      marginRight: '12px',
      padding: '12px 26px',
      whiteSpace: 'nowrap',
      '&:hover': {
        bgcolor: 'primary.main',
        color: 'white'
      }
    },
    backButton: {
      my: '20px',
      [theme.breakpoints.down('sm')]: {
        mt: '15px',
        mb: '10px'
      }
    },
    arrow: { fontFamily: 'Inter', marginRight: '4px' },
    lastSectionHeading: {
      color: 'primary.main',
      fontFamily: 'Tropiline',
      marginBottom: '48px',
      textAlign: 'center'
    },
    relatedArticlesContainer: {
      columnGap: '60px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      justifyContent: 'center',
      marginTop: '20px',
      paddingX: '15px',

      [theme.breakpoints.down('md')]: {
        columnGap: '16px',
        display: 'flex',
        justifyContent: 'flex-start',
        overflowX: 'auto'
      }
    },
    newsLetterSection: {
      mx: 'auto',
      maxWidth: '990px'
    },
    offsetSection: {
      zIndex: -1,
      bgcolor: LL_BACKGROUND_COLOR,
      width: '100%',
      height: '300px'
    },
    articleDescription: {
      maxWidth: '1024px',
      '& a': {
        color: ARTICLE_ANCHOR_COLOR,
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      '& p': {
        lineHeight: '22px',
        mb: '1em',
        '& strong': {
          fontWeight: 500
        }
      },
      '& h1,h2,h3,h4,h5,h6': {
        lineHeight: '24px',
        margin: '30px 0 25px',
        fontWeight: 600
      },
      '& ul,ol': {
        pl: '2em',
        margin: '1em 0'
      },
      '& li': {
        mb: '10px',
        '& strong': {
          fontWeight: 500
        }
      },
      '& img': {
        maxWidth: '1024px',
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center',
        marginBottom: '10px'
      },
      '& figure': {
        marginBottom: '1em'
      },
      '& figcaption': {
        display: 'block',
        opacity: '.6',
        fontSize: '12px',
        fontStyle: 'italic',
        textAlign: 'left'
      },
      '& table': {
        maxWidth: '1024px'
      }
    }
  };
  /********************* END STYLE *********************/

  useEffect(() => {
    if (location) {
      let { pathname } = location;
      let segments = pathname.split('/');
      if (segments[1]) segments = segments.slice(1);
      else segments = segments.slice(2);

      let computedBreadcrumbs = segments.map((segment, index) => {
        let obj: LinkItem = {} as LinkItem;
        obj.value = segment;
        obj.href = '';
        Array.from({ length: index + 1 }).map((_, i) => {
          let tmp = i === index && segments[i] === 'news' ? 'latest-news' : segments[i];
          obj.href = obj.href + `/${tmp}`;
        });

        segment = segment.replace(/-/g, ' ');
        obj.label = segment;

        return obj;
      });
      setBreadcrumbs([homeBreadcrumb, ...computedBreadcrumbs]);
    }
  }, []);

  const {
    craftGqlLatestNewsDefaultEntry: queryData,
    allCraftGqlLatestNewsDefaultEntry: latestArticles
  } = data;

  const newsLetterData = data.craftGqlFaqsFaqsEntry.body.filter(
    (item) => 'typeHandle' in item && item.typeHandle === ReusableComponentsType.newsLetter
  )[0];

  /**************** Social Share ***************/
  let hashtags = '';
  queryData?.newsTags.map((tag) => (hashtags = hashtags.concat(',' + tag.slug.replace(/-/, ''))));
  hashtags = hashtags.concat(',ListingLoop');
  const facebookShareLink = `https://www.facebook.com/sharer.php?u=${location.href}`;
  const twitterShareLink = `https://twitter.com/share?url=${location.href}&text=${queryData?.title}&hashtags=${hashtags}`;
  const linkedinShareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=${queryData?.title}&source=Listing%20Loop`;
  /***************** END Social Share ***************/

  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    setRelatedArticles(
      [].concat(queryData?.relatedArticles || []).concat(latestArticles?.nodes || [])
    );
  }, [data]);
  return (
    <Box>
      <Box sx={sxProps.articleWrapper}>
        <BackButton href="/latest-news" sx={sxProps.backButton} />
        {breadcrumbs.length > minBreadcrumbValue && (
          <Box px="1px">
            <NavbarBreadcrumbs breadcrumbs={breadcrumbs} />
          </Box>
        )}

        <FlexBox sx={sxProps.articleTags}>
          {queryData?.newsTags?.map((tag) => (
            <Link to={`/latest-news/category/${tag.slug}`} style={linkStyle}>
              <Typography sx={sxProps.tagStyle} component="span" variant="body1">
                {tag.title}
              </Typography>
            </Link>
          ))}
        </FlexBox>
        <Typography sx={sxProps.articleTitle} variant="h2">
          {queryData?.title}
        </Typography>
        <Typography sx={sxProps.articlePostDate} variant="body2">
          {formatDateTimeWithMonthName(Date.parse(queryData?.postDate))}
        </Typography>
        <CardMedia
          alt="News Image"
          component="img"
          image={
            (queryData?.heroBackgroundImage?.length > 0 &&
              queryData?.heroBackgroundImage[0]?.url) ??
            ''
          }
          sx={sxProps.articleImg}
        />
        <Typography sx={sxProps.articleSubTitle} variant="h5">
          {queryData?.textSubtitle}
        </Typography>
        <Box
          id="articleDescription"
          dangerouslySetInnerHTML={{ __html: queryData?.richTextContent }}
          color={theme.palette.secondary.main}
          sx={sxProps.articleDescription}
        />

        <Box sx={sxProps.articleShareContainer}>
          <Typography color="secondary.main" fontSize="18px">
            Share
          </Typography>
          <ExternalLink href={facebookShareLink}>
            <IconFacebook
              color={theme.palette.secondary.main}
              height="18px"
              marginLeft="32px"
              width="8px"
            />
          </ExternalLink>
          <ExternalLink href={twitterShareLink}>
            <IconTwitter
              color={theme.palette.secondary.main}
              height="15px"
              marginLeft="32px"
              width="18px"
            />
          </ExternalLink>
          <ExternalLink href={linkedinShareLink}>
            <IconLinkedIn
              color={theme.palette.secondary.main}
              height="18px"
              marginLeft="32px"
              width="18px"
            />
          </ExternalLink>
        </Box>
        <Button sx={sxProps.articleButton} onClick={() => window.open(queryData?.buttonLink)}>
          {queryData?.buttonTitle}{' '}
          <Typography component="span" sx={sxProps.arrow}>
            →
          </Typography>
        </Button>
      </Box>
      <IconWave width="100%" height="auto" marginBottom="-5px" />
      <Box bgcolor={LL_BACKGROUND_COLOR} py="32px">
        {relatedArticles?.length > 0 && (
          <>
            <Typography sx={sxProps.lastSectionHeading} variant="h3">
              You might be interested in
            </Typography>
            <Width80Box sx={sxProps.relatedArticlesContainer}>
              {relatedArticles?.map((props, index) => {
                const date = Date.parse(props.postDate);
                return (
                  index < 3 && (
                    <Link to={`/news/${props.slug}`} style={linkStyle}>
                      <NewsCard
                        customCardStyle={{ background: 'transparent' }}
                        key={date}
                        imageLink={
                          (props.heroBackgroundImage?.length > 0 &&
                            props.heroBackgroundImage[0]?.url) ??
                          ''
                        }
                        date={date}
                        title={props.title}
                        slug={props.slug}
                      />
                    </Link>
                  )
                );
              })}
            </Width80Box>
          </>
        )}
        <Box sx={sxProps.newsLetterSection}>
          <NewsLetterSection
            theme={theme}
            item={newsLetterData as CraftGql_Body_NewsLetter_BlockType}
          />
        </Box>
      </Box>
      <Box bgcolor={LL_BACKGROUND_COLOR} width="100%">
        <Box position="relative">
          <Absolute sx={sxProps.offsetSection} />
        </Box>
      </Box>
    </Box>
  );
};

export const newsArticleQuery = graphql`
  query NewsArticleQuery($slug: String!) {
    craftGqlLatestNewsDefaultEntry(slug: { eq: $slug }) {
      slug
      newsTags {
        title
        slug
      }
      title
      postDate
      heroBackgroundImage {
        url
      }
      textSubtitle
      richTextContent
      buttonTitle
      buttonLink
      relatedArticles {
        slug
        title
        postDate
        heroBackgroundImage {
          url
        }
        newsTags {
          title
          slug
        }
      }
    }
    allCraftGqlLatestNewsDefaultEntry(sort: { fields: postDate, order: DESC }, limit: 3) {
      nodes {
        slug
        title
        postDate
        heroBackgroundImage {
          url
        }
        newsTags {
          title
        }
      }
    }
    craftGqlFaqsFaqsEntry {
      body {
        ... on CraftGQL_body_newsLetter_BlockType {
          id
          typeHandle
          newsLetterSection {
            ... on CraftGQL_newsLetterSection_newsLetterSection_BlockType {
              id
              newsButtonText
              newsSubtitle
              newsTitle
              newsImage {
                url
              }
            }
          }
        }
      }
    }
  }
`;

// const breadQuery = graphql`
//   query getBreadCrumbs {
//     allCraftGqlNavigationHeaderDefaultEntry(filter: { remoteParent: { id: { eq: null } } }) {
//       nodes {
//         title
//         navigationLink
//         remoteChildren {
//           ... on CraftGQL_navigationHeader_default_Entry {
//             title
//             navigationLink
//           }
//         }
//       }
//     }
//   }
// `;

newsArticle.Layout = Layout;
newsArticle.isNavbarBackgroundEmpty = true;

export default newsArticle;
